import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { UserContext, UserContextProvider } from '../UserContext.js'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useLocation } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { InputMask } from 'primereact/inputmask';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import Spreadsheet from "react-spreadsheet";

export const RecordEntry = () => {
    const toast = useRef(null);
    const user = useContext(UserContext)
    const months = ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"];
    const [tenantInfo, setTenantInfo] = useState(null);
    const [parents, setParents] = useState([])
    const [types, setTypes] = useState([])
    const [aircrafts, setAircrafts] = useState([])
    const [passengerFields, setPassengerFields] = useState([])
    const [mailFields, setMailFields] = useState([])
    const [freightFields, setFreightFields] = useState([])
    const location = useLocation();
    const [currentParent, setCurrentParent] = useState(null)
    const urlParams = location.pathname.split('/').slice(2, 5);

    const [freightInbound,setFreightInbound] = useState(0)
    const [freightOutbound, setFreightOutbound] = useState(0)
    const [intlDepartures, setIntlDepartures] = useState(0)
    const [intlArrivals, setIntlArrivals] = useState(0)
    const [miscCharters, setMiscCharters] = useState(0)

    const [submitter, setSubmitter] = useState(null)
    const [submitDate, setSubmitDate] = useState(null)
    const [tenantMonthId, setTenantMonthId] = useState(null)
    const [lastModifiedBy, setLastModifiedBy] = useState(null)
    const [lastModifiedDate, setLastModifiedDate] = useState(null)
    const [landingWeight, setLandingWeight] = useState(null)
    const [data, setData] = useState([]);
    const [readonly, setReadonly] = useState(true)
    const [showExcel, setShowExcel] = useState(false)
    /* All the rows in the mockup are the actual rows, not created by the user. They are generated and the user can enter data into them. The data is mapped like how concession records works */
    useEffect(() => {
        getAllData();

    }, [])
    const getAllData = () => {
        user.apiCall('tenants/' + urlParams[0] + '/tenantInfo').then(response => {
            console.log(response.data)
            setTenantInfo({ ...response.data[0].t, parentName: response.data[0].parentName })
            setCurrentParent(response.data[0].t.tenantParentId)
            let planesForExport = [];
            user.apiCall(`tenants/${response.data[0].t.tenantId}/aircrafts/${urlParams[1]}/${urlParams[2]}`).then(response => {
                console.log(response.data)
                setAircrafts(response.data)
                planesForExport = response.data;
                let sumWeight = 0;
                for (let rec of response.data) {
                    console.log(rec)
                    sumWeight += (rec.numberOfArrivals * rec.faaApprovedMaxGrossLw)
                }
                setLandingWeight(sumWeight)
            })
            user.apiCall(`tenants/${response.data[0].t.tenantId}/dataRecords/${urlParams[1]}/${urlParams[2]}`).then(response => {
                console.log(response.data)
                let data = response.data[0]
                if (data) {
                    setTenantMonthId(data.tenantMonthId)
                    setSubmitter(data.submittedBy)
                    setSubmitDate(data.submittedDate)
                    setIntlDepartures(data.intlDepartures)
                    setIntlArrivals(data.intlArrivals)
                    setFreightInbound(data.freightInbound)
                    setFreightOutbound(data.freightOutbound)
                    setLastModifiedBy(data.lastModifiedBy)
                    setLastModifiedDate(data.lastModifiedDate)
                    setMiscCharters(data.miscCharters)
                }
                user.apiCall('records/types').then(response2 => {
                    setTypes(response2.data)
                    const exportArray = [];
                    let passengerArray = [
                        { isDomestic: true, isRevGenerating: true, isInbound: true, cargoType: 'Passengers', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Passengers', response2.data), units: 'People' },
                        { isDomestic: true, isRevGenerating: true, isInbound: false, cargoType: 'Passengers', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Passengers', response2.data), units: 'People' },
                        { isDomestic: true, isRevGenerating: false, isInbound: true, cargoType: 'Passengers', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Passengers', response2.data), units: 'People' },
                        { isDomestic: true, isRevGenerating: false, isInbound: false, cargoType: 'Passengers', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Passengers', response2.data), units: 'People' },
                        { isDomestic: false, isRevGenerating: true, isInbound: true, cargoType: 'Passengers', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Passengers', response2.data), units: 'People' },
                        { isDomestic: false, isRevGenerating: true, isInbound: false, cargoType: 'Passengers', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Passengers', response2.data), units: 'People' },
                        { isDomestic: false, isRevGenerating: false, isInbound: true, cargoType: 'Passengers', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Passengers', response2.data), units: 'People' },
                        { isDomestic: false, isRevGenerating: false, isInbound: false, cargoType: 'Passengers', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Passengers', response2.data), units: 'People' },
                    ]
                    let mailArray = [
                        { isDomestic: true, isRevGenerating: true, isInbound: true, cargoType: 'Mail', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Mail', response2.data), units: 'Lbs' },
                        { isDomestic: true, isRevGenerating: true, isInbound: false, cargoType: 'Mail', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Mail', response2.data), units: 'Lbs' },
                        { isDomestic: false, isRevGenerating: true, isInbound: true, cargoType: 'Mail', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Mail', response2.data), units: 'Lbs' },
                        { isDomestic: false, isRevGenerating: true, isInbound: false, cargoType: 'Mail', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Mail', response2.data), units: 'Lbs' },
                    ]
                    let freightArray = [
                        { isDomestic: true, isRevGenerating: true, isInbound: true, cargoType: 'Freight', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Freight', response2.data), units: 'Lbs' },
                        { isDomestic: true, isRevGenerating: true, isInbound: false, cargoType: 'Freight', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Freight', response2.data), units: 'Lbs' },
                        { isDomestic: false, isRevGenerating: true, isInbound: true, cargoType: 'Freight', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Freight', response2.data), units: 'Lbs' },
                        { isDomestic: false, isRevGenerating: true, isInbound: false, cargoType: 'Freight', recordValue: 0, tenantMonthRecordTypeId: getTypeId('Freight', response2.data), units: 'Lbs' },
                    ]
                    for (let record of passengerArray) {
                        for (let dRecord of response.data) {
                            if ((dRecord.isDomestic === record.isDomestic) && (dRecord.isRevGenerating === record.isRevGenerating) && (dRecord.isInbound === record.isInbound) && (dRecord.tenantMonthRecordTypeId === record.tenantMonthRecordTypeId)) {
                                console.log(dRecord.recordValue)
                                record.recordValue = dRecord.recordValue
                                record.tenantMonthRecordId = dRecord.tenantMonthRecordId;
                            }
                        }
                        exportArray.push(
                            [
                                { value: record.isDomestic == true ? "Domestic" : "International", readOnly:true },
                                { value: record.isRevGenerating == true ? "Standard" : "Non-Rev", readOnly: true },
                                { value: record.isInbound == true ? "Inbound" : "Outbound", readOnly: true },
                                { value: record.cargoType, readOnly: true },
                                { value: record.recordValue, readOnly: true },
                                { value: record.units, readOnly: true }
                            ]
                        );
                    }
                    for (let record of mailArray) {
                        for (let dRecord of response.data) {
                            if ((dRecord.isDomestic === record.isDomestic) && (dRecord.isRevGenerating === record.isRevGenerating) && (dRecord.isInbound === record.isInbound) && (dRecord.tenantMonthRecordTypeId === record.tenantMonthRecordTypeId)) {
                                console.log(dRecord.recordValue)
                                record.recordValue = dRecord.recordValue
                                record.tenantMonthRecordId = dRecord.tenantMonthRecordId;
                            }
                        }
                        exportArray.push(
                            [
                                { value: record.isDomestic == true ? "Domestic" : "International", readOnly: true },
                                { value: record.isRevGenerating == true ? "Standard" : "Non-Rev", readOnly: true },
                                { value: record.isInbound == true ? "Inbound" : "Outbound", readOnly: true },
                                { value: record.cargoType, readOnly: true },
                                { value: record.recordValue, readOnly: true },
                                { value: record.units, readOnly: true }
                            ]
                        );
                    }
                    for (let record of freightArray) {
                        for (let dRecord of response.data) {
                            if ((dRecord.isDomestic === record.isDomestic) && (dRecord.isRevGenerating === record.isRevGenerating) && (dRecord.isInbound === record.isInbound) && (dRecord.tenantMonthRecordTypeId === record.tenantMonthRecordTypeId)) {
                                console.log(dRecord.recordValue)
                                record.recordValue = dRecord.recordValue
                                record.tenantMonthRecordId = dRecord.tenantMonthRecordId;
                            }
                        }
                        exportArray.push(
                            [
                                { value: record.isDomestic == true ? "Domestic" : "International", readOnly: true },
                                { value: record.isRevGenerating == true ? "Standard" : "Non-Rev", readOnly: true },
                                { value: record.isInbound == true ? "Inbound" : "Outbound", readOnly: true },
                                { value: record.cargoType, readOnly: true },
                                { value: record.recordValue, readOnly: true },
                                { value: record.units, readOnly: true }
                            ]
                        );
                    }
                    exportArray.push([])
                    exportArray.push([{ value: "Aircraft Name", readOnly: true }, { value: "Arrivals", readOnly: true }])
                    for (let plane of planesForExport) {
                        console.log(plane)
                        exportArray.push([
                            { value: plane.tenantAircraftName, readOnly: true },
                            { value: plane.numberOfArrivals, readOnly: true }
                            ])
                    }
                    if (monthDiff() <= 1 || user.acaaUser == true) {
                        setReadonly(false)
                    }
                    setData(exportArray)
                    setPassengerFields(passengerArray)
                    setMailFields(mailArray)
                    setFreightFields(freightArray)
                })
            })
        })
    }

    const monthDiff = () => {
        let recordDate = new Date(urlParams[1], urlParams[2])
        let now = new Date()
        return now.getMonth() - recordDate.getMonth() +
            (12 * (now.getFullYear() - recordDate.getFullYear()))
    }
    const getTypeId = (type, types) => {
        console.log(type,types)
        for (let t of types) {
            if (type === t.tenantMonthRecordTypeName) {
                return t.tenantMonthRecordTypeId
            }
        }
    }
    const inputPassengers = (rowData, row) => {
        return <InputNumber disabled={readonly} style={{ width: '100%' }} value={rowData[row.field]} className="p-inputtext-lg block" onChange={(e) => updatePassengerRecords(e,row)} />
    }
    const inputMail = (rowData, row) => {
        return <InputNumber disabled={readonly} style={{ width: '100%' }} value={rowData[row.field]} className="p-inputtext-lg block" onChange={(e) => updateMailRecords(e, row)} />
    }
    const inputFreight = (rowData, row) => {
        return <InputNumber disabled={readonly} style={{ width: '100%' }} value={rowData[row.field]} className="p-inputtext-lg block" onChange={(e) => updateFreightRecords(e, row)} />
    }
    const inputBodyAircraft = (rowData, row) => {
        console.log(row)
        return <InputNumber disabled={readonly} style={{ width: '100%' }} value={rowData[row.field]} className="p-inputtext-lg block" onChange={(e) => updateAircraftRecords(e, row)} />
    }
    const inputBodyAircraftNonRev = (rowData, row) => {
        console.log(row)
        return <InputNumber disabled={readonly} style={{ width: '100%' }} value={rowData[row.field]} className="p-inputtext-lg block" onChange={(e) => updateAircraftRecordsNonRev(e, row)} />
    }
    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Record Saved', life: 3000 });
    }
    const saveAction = () => {
        let combinedRecords = [].concat(passengerFields, mailFields, freightFields)
        let body = {
            TenantId: tenantInfo.tenantId,
            TenantMonthId: tenantMonthId == null ? undefined : tenantMonthId,
            RecordYear: urlParams[1],
            RecordMonth: urlParams[2],
            FreightTruckedInboundLbs: freightInbound,
            FreightTruckedOutboundLbs: freightOutbound,
            MiscCharters: miscCharters,
            intlArrivals: intlArrivals,
            intlDepartures: intlDepartures,
            LastModifiedBy: user.username,
            LastModifiedDate: new Date(),
            records: combinedRecords,
            aircrafts: aircrafts,
            submittedDate: submitDate == null ? undefined : submitDate,
            originalSubmitalBy: submitter == null ? undefined : submitter,
        }
        console.log(body)
        user.apiCall(`tenants/${urlParams[0]}/dataRecords/${urlParams[1]}/${urlParams[2]}`, { ...body },'POST').then(response => {
            console.log(response.data)
            let sumWeight = 0;
            for (let rec of aircrafts) {
                console.log(rec)
                sumWeight += (rec.numberOfArrivals * rec.faaApprovedMaxGrossLw)
            }
            setLandingWeight(sumWeight)
            showSuccess()
        })
    }
    const updatePassengerRecords = (event, row) => {
        console.log(event, row)
        let dataArray = [...passengerFields];
        let dataRecord = dataArray[row.rowIndex]
        dataRecord.recordValue = event.value
        dataArray[row.rowIndex] = dataRecord
        setPassengerFields(dataArray)
    }
    const updateMailRecords = (event, row) => {
        console.log(event, row)
        let dataArray = [...mailFields];
        let dataRecord = dataArray[row.rowIndex]
        dataRecord.recordValue = event.value
        dataArray[row.rowIndex] = dataRecord
        setMailFields(dataArray)
    }
    const updateFreightRecords = (event, row) => {
        console.log(event, row)
        let dataArray = [...freightFields];
        let dataRecord = dataArray[row.rowIndex]
        dataRecord.recordValue = event.value
        dataArray[row.rowIndex] = dataRecord
        setFreightFields(dataArray)
    }
    const updateAircraftRecords = (event, row) => {
        console.log(event, row)
        let dataArray = [...aircrafts];
        let dataRecord = dataArray[row.rowIndex]
        dataRecord.numberOfArrivals = event.value
        dataArray[row.rowIndex] = dataRecord
        setAircrafts(dataArray)
    }
    const updateAircraftRecordsNonRev = (event, row) => {
        console.log(event, row)
        let dataArray = [...aircrafts];
        let dataRecord = dataArray[row.rowIndex]
        dataRecord.nonRevArrivals = event.value
        dataArray[row.rowIndex] = dataRecord
        setAircrafts(dataArray)
    }
    const isDomesticBody = (rowData) => {
        if (rowData.isDomestic) {
            return "Domestic"
        }
        else {
            return "International"
        }
    }
    const isRevGeneratingBody = (rowData) => {
        if (rowData.isRevGenerating) {
            return "Standard"
        }
        else {
            return "Non Rev"
        }
    }
    const isInboundBody = (rowData) => {
        if (rowData.isInbound) {
            return "Enplaned"
        }
        else {
            return "Deplaned"
        }
    }
    const recordUploadHandler = async (event) => {
        const file = event.files[0];
        const fileReader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob());
        fileReader.readAsDataURL(blob);
        fileReader.onload = function () {
            uploadRecords(file);
        };
    };
    const uploadRecords = async (recordFile) => {
        console.log(typeof recordFile)
        console.log(recordFile)
        let formData = new FormData();
        formData.append("file", recordFile);
        console.log(formData)
        await user.apiCall(`tenants/` + tenantInfo.tenantId + `/upload/` + urlParams[1] + '/' + urlParams[2], formData, 'POST').then((res) => {
            getAllData();
            showSuccess();
        })
    };
    const formatDate = (value) => {
        if(value){
            let date = new Date(value)
            return date.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        }

    }

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <Toast ref={toast} />
                <div className="card">
                    <div style={{ display: "inline-block", width: "100%", marginBottom: "25px" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%", fontSize:"30px", fontWeight:'bold' }}>
                            <div>{tenantInfo?.tenantName} - {tenantInfo?.parentName}</div>
                            <div>{months[urlParams[2]]} {urlParams[1]} {readonly ? "(LOCKED)" : ""}</div>
                            <div style={{ width: '13%' }} ></div>
                        </div>
                    </div>
                    <div style={{ display: "inline-block", width: "100%", marginBottom: "10px" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                            <div ><FileUpload disabled={readonly} mode="basic" name="recordExcel" customUpload={true} uploadHandler={recordUploadHandler} accept=".xlsx" maxFileSize={1000000} />
                                <a href="/assets/template.xlsx" download>Download Template</a>
                            </div>
                            <div ><Button label="Table" aria-label="Table" onClick={() => { setShowExcel(true) }} /></div>

                            <div ><Button className="p-button-success"  disabled={readonly} style={{ float: 'right' }} label="Submit" aria-label="Submit" onClick={saveAction} /></div>
                        </div>
                    </div>
                    <div style={{ display: "inline-block", width: "100%", marginBottom: "10px" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                            <div><span style={{fontWeight:'500'}}>Submitted by: </span><i>{submitter}</i></div>
                            <div><span style={{ fontWeight: '500' }}>Submitted at: </span><i>{formatDate(submitDate)}</i></div>
                            <div><span style={{ fontWeight: '500' }}>Last modified by: </span><i>{lastModifiedBy}</i></div>
                            <div><span style={{ fontWeight: '500' }}>Last modified at: </span><i>{formatDate(lastModifiedDate)}</i></div>
                        </div>
                        
                    </div>
                    <Dialog visible={showExcel} onHide={() => setShowExcel(false)}>
                        <Spreadsheet data={data} onChange={setData} />
                    </Dialog>
                    <div>
                        <Accordion activeIndex={[0,1,2,3,4,5]} multiple>
                            <AccordionTab header="Passengers">
                                <DataTable value={passengerFields} responsiveLayout="scroll" >
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Flight Type" body={isDomesticBody} field='isDomestic' ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Standard/NonRev" body={isRevGeneratingBody} field='isRevGenerating'  ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Enplaned/Deplaned" body={isInboundBody} field='isInbound' ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Cargo Type" field='cargoType' ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' body={inputPassengers} header="Value" field='recordValue' ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center'  header="Units" field='units' ></Column>
                                </DataTable>
                            </AccordionTab>
                            <AccordionTab header="Mail">
                                <DataTable value={mailFields} responsiveLayout="scroll" >
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Flight Type" body={isDomesticBody} field='isDomestic' ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Standard/NonRev" body={isRevGeneratingBody} field='isRevGenerating'  ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Enplaned/Deplaned" body={isInboundBody} field='isInbound' ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Cargo Type" field='cargoType' ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' body={inputMail} header="Value" field='recordValue' ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Units" field='units' ></Column>
                                </DataTable>
                            </AccordionTab>
                            <AccordionTab header="Freight">
                                <DataTable value={freightFields} responsiveLayout="scroll" >
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Flight Type" body={isDomesticBody} field='isDomestic' ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Standard/NonRev" body={isRevGeneratingBody} field='isRevGenerating'  ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Enplaned/Deplaned" body={isInboundBody} field='isInbound' ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Cargo Type" field='cargoType' ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' body={inputFreight} header="Value" field='recordValue' ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} sortable alignHeader='center' align='center' header="Units" field='units' ></Column>
                                </DataTable>
                            </AccordionTab>
                            <AccordionTab header="Arrivals by Aircraft">
                                <DataTable  value={aircrafts} sortOrder="1"  responsiveLayout="scroll" >
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} alignHeader='center' align='center' header="Name" field='tenantAircraftName'  ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} alignHeader='center' align='center' body={inputBodyAircraft} field='numberOfArrivals' header="Arrivals" ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} alignHeader='center' align='center' body={inputBodyAircraftNonRev} field='nonRevArrivals' header="Arrivals (NonRev)" ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} alignHeader='center' align='center' field='faaApprovedMaxGrossLw' header="FAA Max Gross Lw" ></Column>
                                    <Column headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} alignHeader='center' align='center'  ></Column>
                                </DataTable>
                            </AccordionTab>
                            <AccordionTab header="International/Misc Charters">
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: "50%", margin: 'auto', marginTop: '10px' }}>
                                    <span style={{ width: '30%' }} className="p-float-label">
                                        <InputNumber disabled={readonly} id="intDeps" style={{ width: '100%' }} className="p-inputtext-lg block" value={intlDepartures} onChange={(e) => setIntlDepartures(e.value)} />
                                        <label htmlFor="intDeps">International Departures</label>
                                    </span>
                                    <span style={{ width: '30%' }} className="p-float-label">
                                        <InputNumber disabled={readonly} id="intArvs" style={{ width: '100%' }} className="p-inputtext-lg block" value={intlArrivals} onChange={(e) => setIntlArrivals(e.value)} />
                                        <label htmlFor="intArvs">International Arrivals</label>
                                    </span>
                                    <span style={{ width: '30%' }} className="p-float-label">
                                        <InputNumber disabled={readonly} id="miscChar" style={{ width: '100%' }} className="p-inputtext-lg block" value={miscCharters} onChange={(e) => setMiscCharters(e.value)} />
                                        <label htmlFor="miscChar">Misc Charters</label>
                                    </span>
                                </div>
                            </AccordionTab>
                            <AccordionTab header="Excess Cargo">
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: "50%", margin: 'auto', marginTop: '10px' }}>
                                    <span style={{ width: '30%' }} className="p-float-label">
                                        <InputNumber disabled={readonly} id="carIn" style={{ width: '100%' }} className="p-inputtext-lg block" value={freightInbound} onChange={(e) => setFreightInbound(e.value) } />
                                        <label htmlFor="carIn">Excess Cargo Inbound</label>
                                    </span>
                                    <span style={{ width: '30%' }} className="p-float-label">
                                        <InputNumber disabled={readonly} id="carOut" style={{ width: '100%' }} className="p-inputtext-lg block" value={freightOutbound} onChange={(e) => setFreightOutbound(e.value)} />
                                        <label htmlFor="carOut">Excess Cargo Outbound</label>
                                    </span>
                                </div>
                            </AccordionTab>
                        </Accordion>   
                    </div>
                    <div style={{ display: "inline-block", width: "100%", marginTop: "10px" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
                            <div>Landing Weight: {landingWeight} lbs</div>
                            <div>Rate per 1,000 lbs: {tenantInfo?.dollarsPerThousandLbsRate}</div>
                            <div>Total Due: ${(tenantInfo?.dollarsPerThousandLbsRate * (landingWeight / 1000)).toFixed(2)}</div>
                        </div>

                    </div>  
                </div>
            </div>
        </div>
    );
}
