import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { UserContext, UserContextProvider } from '../../UserContext.js'
import { Dialog } from 'primereact/dialog';
import Spreadsheet from "react-spreadsheet";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

const month = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];
const monthShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const monthValues = [{ name:"January",value:0},
    { name: "February", value: 1 },
    { name: "March", value: 2 },
    { name: "April", value: 3 },
    { name: "May", value: 4 },
    { name: "June", value: 5 },
    { name: "July", value: 6 },
    { name: "August", value: 7 },
    { name: "September", value: 8 },
    { name: "October", value: 9 },
    { name: "November", value: 10 },
    { name: "December", value: 11 }];
export const AdminLanding = () => {
    const user = useContext(UserContext)
    const [tenants, setTenants] = useState([]);
    const [columnHeaders, setColumnHeaders] = useState([]);
    const [invoiceVisible, setInvoiceVisible] = useState(false);
    const [data, setData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(4);
    const [selectedYear, setSelectedYear] = useState(2023);
    const [globalFilter, setGlobalFilter] = useState(null);
    useEffect(() => {
        user.apiCall('tenants/dashboard/' + (user.type?.id == null ? "" : user.type?.id), user.tenantInfo, 'post').then(res => {
            let resAry = [];
            for (let r of res.data) {
                if (resAry.filter(e => e.tenantId === r.tenantId).length < 1) {
                    let val = {};
                    val.tenantParentName = r.tenantParentName
                    val.tenantId = r.tenantId
                    val.tenantName = r.tenantName
                    val.tenantParentId = r.tenantParentId
                    val.dollarsPerThousandLbsRate = r.dollarsPerThousandLbsRate
                    val.tm = []
                    val.tm.push({ recordYear: r.recordYear, recordMonth: r.recordMonth })
                    resAry.push(val)
                }
                else {
                    var index = resAry.map(function (e) { return e.tenantId; }).indexOf(r.tenantId);
                    resAry[index].tm.push({ recordYear : r.recordYear, recordMonth : r.recordMonth })
                }
            }
            setTenants(resAry)
        })
        buildColumnHeaders()
    },[])
    const buildColumnHeaders = () => {
        var d = new Date();
        let monthArray = [];
        monthArray.push(month[d.getMonth()] + " " + d.getFullYear())
        for (let i = 0; i < 3; i++) {
            d.setMonth(d.getMonth() - 1)
            monthArray.push(month[d.getMonth()] + " " + d.getFullYear())
        }

        setColumnHeaders(monthArray)
    }
    const dayBodyTemplate = (rowData, props) => {
        let monthYear = props.header.split(' ');
        for (let rec of rowData.tm) {
            if (rec.recordMonth == month.indexOf(monthYear[0]) && rec.recordYear == monthYear[1]) {
                return <span><Tooltip target={".valid" + rowData.tenantId + props.field} content="Record Submitted" position="left" /><i style={{ color: "green", fontSize: "30px" }} className={"pi pi-check valid" + rowData.tenantId + props.field}></i></span>
            }
        }        
            return <span><Tooltip target={".error" + rowData.tenantId + props.field} content="No Record Submitted" position="left" /><i style={{ color: "red", fontSize: "30px" }} className={"pi pi-times error" + rowData.tenantId + props.field}></i></span >
        

    }
    const nameTemplate = (rowData) => {
        return <Button style={{ width: "100%" }} label={rowData.t.tenantName}  />

    }
    const onMonthChange = (e) => {
        setSelectedMonth(e.value)
        getTableData(e.value, selectedYear)
    }
    const onYearChange = (e) => {
        setSelectedYear(e.value)
        getTableData(selectedMonth, e.value)
    }
    const tableAction = () => {
        getTableData()
        setInvoiceVisible(true)
    }
    const getTableData = (month, year) => {
        let callMonth = month == null ? selectedMonth : month
        let callYear = year == null ? selectedYear : year
        user.apiCall('invoices/' + callYear + '/' + callMonth).then(res => {
            let tableArray = []
            tableArray.push([
                { value: "Parent", readOnly: true },
                { value: "Partner", readOnly: true },
                { value: "Remarks", readOnly: true },
                { value: "Gross Landing Weight", readOnly: true },
                { value: "Fixed Rate", readOnly: true },
                { value: "Invoice Amount", readOnly: true }
            ])
            for (let record of res.data) {
                tableArray.push(
                    [
                        { value: record.tenantParentName , readOnly: true },
                        { value: record.tenantName , readOnly: true },
                        { value: ""  },
                        { value: record.grossLandingWeight, readOnly: true },
                        { value: record.fixedRate, readOnly: true },
                        { value: record.invoiceAmount, readOnly: true }
                    ])
            }
            setData(tableArray)
        })
    }
    const renderHeader = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search Partners" />
                </span>
            </div>
        );
    }
    const header = renderHeader();
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    {user.acaaUser === true ? <Button style={{ marginBottom: '5px' }} label="Invoice Table" onClick={tableAction} /> : <></>}
                    <Dialog header="Invoice Table" visible={invoiceVisible} onHide={() => setInvoiceVisible(false)}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%", marginBottom:'10px' }}>
                            <Dropdown value={selectedMonth} options={monthValues} onChange={onMonthChange} optionLabel="name" />
                            <Dropdown value={selectedYear} options={[2023,2022]} onChange={onYearChange}   />
                        </div>
                       <Spreadsheet data={data} onChange={setData} />
                    </Dialog>
                    <div>
                        <DataTable value={tenants} sortOrder="1" header={header} responsiveLayout="scroll" globalFilter={globalFilter}>
                            <Column  field="tenantParentName"  bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center'  sortable header="Partner Parent" style={{ width: '18%' }} ></Column>
                            <Column  field="tenantName"  bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Partner" ></Column>
                            <Column body={dayBodyTemplate} bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header={columnHeaders[3]} ></Column>
                            <Column body={dayBodyTemplate} bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header={columnHeaders[2]} ></Column>
                            <Column body={dayBodyTemplate} bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header={columnHeaders[1]} ></Column>
                            <Column body={dayBodyTemplate} bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header={columnHeaders[0]} ></Column>

                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    );
}
