import React, { useState, useEffect, useRef, useContext } from 'react'
import { UserContext, UserContextProvider } from './UserContext.js'
import { TabMenu } from 'primereact/tabmenu';
import { useHistory } from 'react-router-dom';

export const AppTabs = (props) => {
    const user = useContext(UserContext)
    const [tenants, setTenants] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const history = useHistory();
    useEffect(() => {
        console.log(user)
        user.apiCall('users/' + user?.userId + '/types/' + user?.type?.id +'/tenants').then((res) => {
            console.log(res)
            var tabs = res.data.map((tab, idx) => {
                return (
                    { label: tab.tenantName, value: tab.tenantId }
                )
            })
            setTenants(tabs)
        })
    }, [user]);

    if (tenants) {
        return (
            <TabMenu model={tenants} activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index); user.setTenant({ id: e.value.value, name: e.value.label }); history.push('/'); }}/>
        );
    }
    else {
        return <TabMenu />;
    }
}
