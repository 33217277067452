import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';
import { UserContext, UserContextProvider } from './UserContext.js'
import AppTopBar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import { Dashboard } from './pages/Dashboard';
import { AdminLanding } from './pages/admin/AdminLanding';
import { PartnerList } from './pages/admin/PartnerList';
import { PartnerDetails } from './pages/PartnerDetails';
import { TenantAdv } from './pages/TenantAdv';
import { RecordEntry } from './pages/RecordEntry';
import PrimeReact from 'primereact/api';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./auth/AuthConfig";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import { AppTabs } from './AppTabs'

const App = () => {
	const [menuActive, setMenuActive] = useState(false);
	const [menuMode, setMenuMode] = useState('static');
	const [overlayMenuActive, setOverlayMenuActive] = useState(false);
	const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
	const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
	const [configActive, setConfigActive] = useState(false);
    const { instance, accounts } = useMsal();

	let menuClick = false;
	let configClick = false;
    const user = useContext(UserContext)
	const menu = [
		{
			label: 'Pages',
			icon: 'pi pi-fw pi-star-o',
			items: [
                { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
                { label: 'Details', icon: 'pi pi-fw pi-home', to: '/partners/' + user?.tenant?.name },
			]
		}
	];
    const adminMenu = [
        {
            label: 'Pages',
            icon: 'pi pi-fw pi-star-o',
            items: [
                { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
                { label: 'Partners', icon: 'pi pi-fw pi-home', to: '/partners' },
            ]
        }
    ];
    const advMenu = [
        {
            label: 'Pages',
            icon: 'pi pi-fw pi-star-o',
            items: [
                { label: 'Adv', icon: 'pi pi-fw pi-home', to: '/adv' },
            ]
        }
    ];
    const routers = [
        { path: "/", component: AdminLanding, exact: true },
        { path: "/partners/:tenantId", component: PartnerDetails, exact: true },
        { path: "/partners/:tenantId/:year/:month", component: RecordEntry, exact: true },
    ];
	const adminRouters = [
        { path: "/", component: AdminLanding, exact: true },
        { path: "/partners", component: PartnerList, exact: true },
        { path: "/partners/:tenantId", component: PartnerDetails, exact: true },
        { path: "/partners/:tenantId/:year/:month", component: RecordEntry, exact: true },
	];
    const advRouters = [
        { path: "/", component: TenantAdv, exact: true },
        { path: "/adv", component: TenantAdv, exact: true },
    ];
	const onDocumentClick = () => {
		if (!menuClick) {
			if (isHorizontal() || isSlim()) {
				setMenuActive(false);
			}

			hideOverlayMenu();
		}

		if (configActive && !configClick) {
			setConfigActive(false);
		}

		menuClick = false;
		configClick = false;
	};

	const onMenuClick = () => {
		menuClick = true;
	};

	const onMenuitemClick = (event) => {
		if (!event.item.items) {
			hideOverlayMenu();
		}

		if (!event.item.items && (isSlim() || isHorizontal())) {
			setMenuActive(false);
		}
	};

	const onRootMenuitemClick = () => {
		setMenuActive(prevMenuActive => !prevMenuActive);
	};

	const onMenuButtonClick = (event) => {
		menuClick = true;

		if (menuMode === 'overlay') {
			setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
		}
		else {
			if (isDesktop())
				setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
			else
				setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
		}

		event.preventDefault();
	};

	const onTopbarMenuButtonClick = (event) => {
		hideOverlayMenu();
		event.preventDefault();
	};

	const hideOverlayMenu = () => {
		setOverlayMenuActive(false);
		setStaticMenuMobileActive(false);
	};

	const isDesktop = () => {
		return window.innerWidth > 1024;
	};

	const isHorizontal = () => {
		return menuMode === 'horizontal';
	};

	const isSlim = () => {
		return menuMode === 'slim';
	};

	const isMenuVisible = () => {
		if (isDesktop()) {
			if (menuMode === 'static')
				return !staticMenuDesktopInactive;
			else if (menuMode === 'overlay')
				return overlayMenuActive;
			else
				return true;
		}
		else {
			if (staticMenuMobileActive)
				return true;
			else if (menuMode === 'overlay')
				return overlayMenuActive;
			else
				return true;
		}
	};

	let containerClassName = classNames('layout-wrapper', {
		'menu-layout-static': menuMode !== 'overlay',
		'menu-layout-overlay': menuMode === 'overlay',
		'layout-menu-overlay-active': overlayMenuActive,
		'menu-layout-slim': menuMode === 'slim',
		'menu-layout-horizontal': menuMode === 'horizontal',
		'layout-menu-static-inactive': staticMenuDesktopInactive,
		'layout-menu-static-active': staticMenuMobileActive,
		'p-input-filled': false,
		'p-ripple-disabled': true,
	});
	let menuClassName = classNames('layout-menu-container', { 'layout-menu-dark': false, 'layout-menu-container-inactive': !isMenuVisible() });
    const setTenant = (tenantId) => {
        user.setTenant(tenantId)
    }
	return (
		<div className={containerClassName} onClick={onDocumentClick}>
			<div className="layout-wrapper-content">
				<AppTopBar
					onMenuButtonClick={onMenuButtonClick}
					onTopbarMenuButtonClick={onTopbarMenuButtonClick}
                />                
				<UnauthenticatedTemplate>
					{
                        () => {
                                instance.loginRedirect(loginRequest).catch(e => {
                                    console.error(e);
                                })                            
						}
					}
                </UnauthenticatedTemplate>

				<div className={menuClassName} onClick={onMenuClick}>
					<div className="menu-scroll-content">
                        <AppMenu setTenant={setTenant}
                            user={user}
                            model={user?.acaaUser === true ? adminMenu : (user?.type?.id == 'ba1e177a-2ac3-4ed3-8ad8-3d4f22285e24' ? advMenu :menu)}
							menuMode={menuMode}
							active={menuActive}
							onMenuitemClick={onMenuitemClick}
							onRootMenuitemClick={onRootMenuitemClick}
						/>
					</div>
				</div>
                {user.acaaUser === true ?
                    <div className="layout-main">
                        {
                            adminRouters.map((router, index) => {
                                if (router.exact) {
                                    return (
                                        <Route key={`router${index}`}
                                            path={router.path}
                                            exact
                                            component={router.component}
                                        />
                                    )
                                }

                                return (
                                    <Route key={`router${index}`}
                                        path={router.path}
                                        component={router.component}
                                    />
                                )
                            })
                        }
                    </div>
                    :
                    <div className="layout-main">
                        <AppTabs/>
                        {
                            user?.type?.id == 'ba1e177a-2ac3-4ed3-8ad8-3d4f22285e24' ?
                            advRouters.map((router, index) => {
                                if (router.exact) {
                                    return (
                                        <Route key={`router${index}`}
                                            path={router.path}
                                            exact
                                            component={router.component}
                                        />
                                    )
                                }

                                return (
                                    <Route key={`router${index}`}
                                        path={router.path}
                                        component={router.component}
                                    />
                                )
                            })
                                :
                                routers.map((router, index) => {
                                    if (router.exact) {
                                        return (
                                            <Route key={`router${index}`}
                                                path={router.path}
                                                exact
                                                component={router.component}
                                            />
                                        )
                                    }

                                    return (
                                        <Route key={`router${index}`}
                                            path={router.path}
                                            component={router.component}
                                        />
                                    )
                                })
                        }
                    </div>
                    }

				<div className="layout-mask modal-in"></div>

				<AppFooter />
			</div>
		</div>
	);
}

export default App;
