import React, { createContext, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { ProgressSpinner } from 'primereact/progressspinner';
import AppSettings from "./AppSettings.json";
import axios from 'axios'
// create context
const UserContext = createContext();
const baseUrl = AppSettings.EDDY_API;
const authUrl = AppSettings.AUTH_API;
const baseKey = AppSettings.EDDY_KEY;
const authKey = AppSettings.AUTH_KEY;

const UserContextProvider = ({ children }) => {
    // the value that will be given to the context
    const [userInfo, setUserInfo] = useState(null);
    const [userToken, setUserToken] = useState(null);
    const [loadingError, setLoadingError] = useState(null);
    const [currentTenant, setCurrentTenant] = useState(null);
    const [currentReportingType, setCurrentReportingType] = useState(null);
    const [loading, setLoading] = useState(true);
    const { instance, accounts } = useMsal();
    // fetch a user from a fake backend API
    useEffect(() => {
        const fetchUser = async () => {
            if (accounts.length > 0) {
                await axios.get(authUrl + "CreateApiJwt", {
                    headers: {
                        'ocp-apim-subscription-key': authKey
                    }
                }).then(async (response) => {
                    setUserToken(response.data);
                    try {
                        await axios.get(baseUrl + `users/${accounts[0]?.username}`, {
                            headers: {
                                Authorization: 'Bearer ' + response.data,
                                'ocp-apim-subscription-key': baseKey
                            }
                        }).then(async res => {
                            let uObj = { ...accounts[0] }
                            if (uObj.username.toUpperCase().endsWith("@FLYPITTSBURGH.COM")) {
                                uObj.acaaUser = true;
                            }                            
                            uObj.isManager = res?.data.isManager;
                            uObj.userId = res?.data[0]?.userId;
                            setCurrentTenant(res?.data[0]?.tenantId);
                            
                            if (!uObj.acaaUser) {
                                await axios.get(baseUrl + `users/ ${res?.data[0]?.userId}/tenants`, {
                                    headers: {
                                        Authorization: 'Bearer ' + response.data,
                                        'ocp-apim-subscription-key': baseKey

                                    }
                                }).then(async res2 => {
                                    let userInfo = { ...uObj };
                                    userInfo.tenantInfo = res2.data
                                    setUserInfo(userInfo);
                                    setCurrentTenant({ id: res2.data[0]?.tenantId, name: res2.data[0]?.name })                                   
                                    await axios.get(baseUrl + `users/ ${res?.data[0]?.userId}/reportingTypes`, {
                                        headers: {
                                            Authorization: 'Bearer ' + response.data,
                                            'ocp-apim-subscription-key': baseKey

                                        }
                                    }).then(res3 => {
                                        userInfo.reportingInfo = res3.data
                                        setCurrentReportingType(res3.data[0]);
                                        setLoading(false);
                                    })
                                })

                            }
                            else {
                                setUserInfo(uObj)
                                setLoading(false);
                            }
                        })

                    }
                    catch (exception) {
                        setLoadingError(exception)
                    }

                })
            }
        };

        fetchUser();
    }, [accounts]);
    const checkTokenExpiration = () => {
        let splitToken = userToken.split('.')
        let decodedHeader = atob(splitToken[1])
        let decodedHeaderObj = JSON.parse(decodedHeader)
        let expiry = new Date(decodedHeaderObj.exp * 1000)
        if (expiry < new Date()) {
            axios.get(authUrl + "CreateApiJwt", {
                headers: {
                    'ocp-apim-subscription-key': authKey
                }
            }).then((response) => setUserToken(response.data))
        }
    }
    const apiCall = async (uri, body, operation) => {
        let retVal
        if (userToken)
            checkTokenExpiration();
        switch (operation?.toUpperCase()) {
            case 'GET':
                await axios.get(baseUrl + uri, {
                    headers: {
                        Authorization: 'Bearer ' + userToken,
                        'ocp-apim-subscription-key': baseKey,                     
                        tenantId: (userInfo.acaaUser === true ? 'F0000000-0000-0000-0000-000000000000' : currentTenant.id),
                        userId: (userInfo.acaaUser === true ? 'F0000000-0000-0000-0000-000000000000' : userInfo.tenantInfo[0].userId)
                    }
                }).then((res) => {
                    retVal = res;
                });
                break;
            case 'PUT':
                await axios.put(baseUrl + uri, body, {
                    headers: {
                        Authorization: 'Bearer ' + userToken,
                        'ocp-apim-subscription-key': baseKey,
                        tenantId: (userInfo.acaaUser === true ? 'F0000000-0000-0000-0000-000000000000' : currentTenant.id),
                        userId: (userInfo.acaaUser === true ? 'F0000000-0000-0000-0000-000000000000' : userInfo.tenantInfo[0].userId)
                    }
                }).then((res) => {
                    retVal = res;
                });
                break;
            case 'POST':
                await axios.post(baseUrl + uri, body, {
                    headers: {
                        Authorization: 'Bearer ' + userToken,
                        'ocp-apim-subscription-key': baseKey,
                        tenantId: (userInfo?.acaaUser === true ? 'F0000000-0000-0000-0000-000000000000' : currentTenant.id),
                        userId: (userInfo?.acaaUser === true ? 'F0000000-0000-0000-0000-000000000000' : userInfo.tenantInfo[0].userId)
                    }
                }).then((res) => {
                    retVal = res;
                });
                break;
            case 'DELETE':
                await axios.delete(baseUrl + uri, {
                    headers: {
                        Authorization: 'Bearer ' + userToken,
                        'ocp-apim-subscription-key': baseKey,
                        tenantId: (userInfo.acaaUser === true ? 'F0000000-0000-0000-0000-000000000000' : currentTenant.id),
                        userId: (userInfo.acaaUser === true ? 'F0000000-0000-0000-0000-000000000000' : userInfo.tenantInfo[0].userId)
                    },
                    data: body
                }).then((res) => {
                    retVal = res;
                });
                break;
            default:
                await axios.get(baseUrl + uri, {
                    headers: {
                        Authorization: 'Bearer ' + userToken,
                        'ocp-apim-subscription-key': baseKey,
                        tenantId: (userInfo.acaaUser === true ? 'F0000000-0000-0000-0000-000000000000' : currentTenant.id),
                        userId: (userInfo.acaaUser === true ? 'F0000000-0000-0000-0000-000000000000' : userInfo.tenantInfo[0].userId)
                    }
                }).then((res) => {
                    retVal = res;
                });
                break;
        }
        return retVal;
    }
    const localCall = async (uri, body, operation) => {
        let retVal
        switch (operation?.toUpperCase()) {
            case 'GET':
                await axios.get('http://localhost:7164/api/' + uri, {}).then((res) => {
                    retVal = res;
                });
                break;
            case 'PUT':
                await axios.put('http://localhost:7164/api/' + uri, body, {}).then((res) => {
                    retVal = res;
                });
                break;
            case 'POST':

                await axios.post('http://localhost:7164/api/' + uri, body, {}).then((res) => {
                    retVal = res;
                });
                break;
            case 'DELETE':
                await axios.delete('http://localhost:7164/api/' + uri, {
                    data: body
                }).then((res) => {
                    retVal = res;
                });
                break;
            default:
                await axios.get('http://localhost:7164/api/' + uri, {}).then((res) => {
                    retVal = res;
                });
                break;
        }
        return retVal;
    }
    if (loadingError) {
        return <UserContext.Provider value={{ ...userInfo, tenant: currentTenant, setTenant: setCurrentTenant }}>
            {loadingError}
        </UserContext.Provider>
    }
    else if (loading === true && accounts.length > 0) {
        return <UserContext.Provider value={{ ...userInfo, tenant: currentTenant, setTenant: setCurrentTenant }}>
            <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
        </UserContext.Provider>
    }
    else {
        return (
            // the Provider gives access to the context to its children
            <UserContext.Provider value={{ ...userInfo, tenant: currentTenant, type: currentReportingType, setTenant: setCurrentTenant, setType: setCurrentReportingType, apiCall: apiCall, localCall: localCall }}>
                {children}
            </UserContext.Provider>
        );
    }

};

export { UserContext, UserContextProvider };
