import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { UserContext, UserContextProvider } from '../../UserContext.js'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';

export const PartnerList = () => {
    const user = useContext(UserContext)
    const history = useHistory();
    const [tenants, setTenants] = useState([]);
    const [parents, setParents] = useState([]);
    const [visible, setVisible] = useState(false);
    const [newParentDialog, setNewParentDialog] = useState(false);
    const [newParentName, setNewParentName] = useState("");
    const [newPartnerDialog, setNewPartnerDialog] = useState(false);
    const [newPartnerName, setNewPartnerName] = useState(null);
    const [newPartnerParent, setNewPartnerParent] = useState(null);
    const [newPartnerRate, setNewPartnerRate] = useState(0);
    useEffect(() => {
        user.apiCall('tenants/dashboard', user.tenantInfo, 'post').then(res => {
            let resAry = [];
            for (let r of res.data) {
                if (resAry.filter(e => e.tenantId === r.tenantId).length < 1) {
                    let val = {};
                    val.tenantParentName = r.tenantParentName
                    val.tenantId = r.tenantId
                    val.tenantName = r.tenantName
                    val.tenantParentId = r.tenantParentId
                    val.dollarsPerThousandLbsRate = r.dollarsPerThousandLbsRate
                    val.tm = []
                    val.tm.push({ recordYear: r.recordYear, recordMonth: r.recordMonth })
                    resAry.push(val)
                }
                else {
                    var index = resAry.map(function (e) { return e.tenantId; }).indexOf(r.tenantId);
                    resAry[index].tm.push({ recordYear: r.recordYear, recordMonth: r.recordMonth })
                }
            }
            setTenants(resAry)
        })
        user.apiCall('parents').then(res => {
            setParents(res.data)
        })
    }, [])

    const onHide = () => {
        setVisible(false)
    }
    const deleteButtonBody = (rowData) => {
        return <Button icon="pi pi-times" style={{ marginLeft: '40%' }} className="p-button-rounded p-button-danger" aria-label="Cancel" onClick={() => {
            user.apiCall('parents/' + rowData.tenantParentId, {}, 'DELETE').then(() => {
                user.apiCall('parents').then(res => {
                    setParents(res.data)
                })
        }) }} />;
    }
    const nameTemplate = (rowData) => {
        return <Button style={{ width: "100%" }} label={rowData?.tenantName} onClick={() => {history.push("/partners/" + rowData?.tenantName) }} />

    }
    const saveParent = () => {
        user.apiCall('parents', { tenantParentName: newParentName }, 'post').then(res => {
            user.apiCall('parents').then(res => {
                setParents(res.data)
                setNewParentDialog(false)
            })
        })
    }
    const savePartner = () => {
        user.apiCall('tenants', { tenantParentId: newPartnerParent, tenantName: newPartnerName, dollarsPerThousandLbsRate: newPartnerRate }, 'post').then(res => {
            user.apiCall('tenants/dashboard', user.tenantInfo, 'post').then(res => {
                let resAry = [];
                for (let r of res.data) {
                    if (resAry.filter(e => e.tenantId === r.tenantId).length < 1) {
                        let val = {};
                        val.tenantParentName = r.tenantParentName
                        val.tenantId = r.tenantId
                        val.tenantName = r.tenantName
                        val.tenantParentId = r.tenantParentId
                        val.dollarsPerThousandLbsRate = r.dollarsPerThousandLbsRate
                        val.tm = []
                        val.tm.push({ recordYear: r.recordYear, recordMonth: r.recordMonth })
                        resAry.push(val)
                    }
                    else {
                        var index = resAry.map(function (e) { return e.tenantId; }).indexOf(r.tenantId);
                        resAry[index].tm.push({ recordYear: r.recordYear, recordMonth: r.recordMonth })
                    }
                }
                setTenants(resAry)
                setNewPartnerDialog(false)
            })
        })
    }
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="card">
                    <div>
                        <Dialog visible={visible} header="Parent Organizations" onHide={onHide}  style={{ width: '25vw' }}>
                            <DataTable  value={parents} sortOrder="1"  responsiveLayout="scroll" >
                                <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Parent" field="tenantParentName" ></Column>
                                <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' body={deleteButtonBody} header={<Button style={{  marginLeft: '40%' }} label="New" aria-label="New"  onClick={() => setNewParentDialog(true) }  />}></Column>
                            </DataTable>
                        </Dialog>
                        <Dialog header="New Parent" visible={newParentDialog} style={{ width: '35vw', overflowY: 'hidden' }} onHide={() => setNewParentDialog(false)}>
                            Name: <InputText style={{ float: 'inline', marginLeft: '35%', marginRight: '35%' }} value={newParentName} onChange={(e) => setNewParentName(e.target.value)} />
                            <div style={{ width: "50%", margin: 'auto' }}><Button style={{ marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', width: '100%' }} label='Add Parent' className="p-button-success" onClick={saveParent} /></div>
                        </Dialog>
                        <Dialog header="New Partner" visible={newPartnerDialog} style={{ width: '20vw', overflowX: 'hidden' }} onHide={() => setNewPartnerDialog(false)}>
                            <div style={{display:'grid'}}>
                               Name: <div style={{ display: 'block', margin:'auto' }}><InputText  value={newPartnerName} onChange={(e) => setNewPartnerName(e.target.value)} /></div>
                                 Parent: <div style={{ display: 'block', margin: 'auto' }}><Dropdown optionLabel="tenantParentName" optionValue="tenantParentId" value={newPartnerParent} options={parents} onChange={(e) => setNewPartnerParent(e.value)} placeholder="Select a Parent Org" /></div>                            
                               Rate: <div style={{ display: 'block', margin: 'auto' }}><InputNumber value={newPartnerRate} onChange={(e) => setNewPartnerRate(e.value)} /></div>
                            </div>
                            <div style={{ width: "50%", margin: 'auto' }}><Button style={{ marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', width:'100%'}} label='Add Partner' className="p-button-success" onClick={savePartner} /></div>
                        </Dialog>
                        <div style={{ margin: 'auto', width: '50%', marginBottom: '10px !important', height:'50px' }}>
                            <Button style={{ float: 'right', marginBottom: '10px' }} label="Partner Parent List" aria-label="Partner Parent List" onClick={() => setVisible(true)} />
                            <Button style={{ float: 'right', marginBottom: '10px', marginRight: '10px' }} onClick={() => setNewPartnerDialog(true)} label="New Partner" aria-label="New Partner" />
                        </div>
                        <DataTable style={{ marginLeft: '25%',marginRight:'25%' }} value={tenants} sortOrder="1"  responsiveLayout="scroll" >
                            <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Partners" body={nameTemplate} field="tenantName" ></Column>
                            <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Parent" field="tenantParentName" ></Column>
                            <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="$s/1000#" field="dollarsPerThousandLbsRate" ></Column>
                        </DataTable>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}
