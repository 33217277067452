import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { UserContext, UserContextProvider } from '../UserContext.js'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useLocation } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { InputMask } from 'primereact/inputmask';
import { useHistory } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
export const PartnerDetails = () => {
    const toast = useRef(null);
    const history = useHistory();
    const startDate = new Date('2022-12-01T00:00:00');
    const today = new Date();
    const user = useContext(UserContext)
    const [visible, setVisible] = useState(false);
    const [users, setUsers] = useState(null);
    const [aircrafts, setAircrafts] = useState(null);
    const [dataRecords, setDataRecords] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [tenantInfo, setTenantInfo] = useState(null);
    const [parents, setParents] = useState([])
    const location = useLocation();
    const [currentParent, setCurrentParent] = useState(null)
    const [newUserDialog, setNewUserDialog] = useState(false);
    const [newUserManager, setNewUserManager] = useState(false);
    const [newUserEmail, setNewUserEmail] = useState(null);
    const [newUserPhone, setNewUserPhone] = useState(null)
    const [newAircraftDialog, setNewAircraftDialog] = useState(false);
    const [newAircraftName, setNewAircraftName] = useState(null);
    const [newAircraftTonnage, setNewAircraftTonnage] = useState(false);
    const [partnerName, setPartnerName] = useState('');
    const [dollarRate, setDollarRate] = useState(0.0);
    const [editDialog, setEditDialog] = useState(false);
    const partner = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const months = ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"];
    useEffect(() => {
        console.log(partner)
        user.apiCall('tenants/' + partner + '/tenantInfo').then(response => {
            console.log(response.data[0])
            setTenantInfo(response.data[0].t)
            setPartnerName(response.data[0].t.tenantName)
            setCurrentParent(response.data[0].t.tenantParentId)
            setDollarRate(response.data[0].t.dollarsPerThousandLbsRate)
            user.apiCall('tenants/' + response.data[0].t.tenantId + '/users').then(res => {

                setUsers(res.data)
            })
            user.apiCall('tenants/' + response.data[0].t.tenantId + '/aircrafts').then(res => {

                console.log(res.data)
                setAircrafts(res.data)
            })
            user.apiCall('tenants/' + response.data[0].t.tenantId + '/dataRecords').then(res => {
                console.log(res)
                let sums = {years: {}}
                for (let rec of res.data) {
                    if (sums.years[rec.recordYear] == null) {
                        sums.years[rec.recordYear] = { months: {}}
                    }
                    if (sums.years[rec.recordYear].months[rec.recordMonth] == null) {
                        sums.years[rec.recordYear].months[rec.recordMonth] = { domestic: {}, international: {} }
                    }
                    if (sums.years[rec.recordYear].months[rec.recordMonth].domestic[rec.tenantMonthRecordName] == null) {
                        sums.years[rec.recordYear].months[rec.recordMonth].domestic[rec.tenantMonthRecordName] = {value:0}
                    }
                    if (sums.years[rec.recordYear].months[rec.recordMonth].international[rec.tenantMonthRecordName] == null) {
                        sums.years[rec.recordYear].months[rec.recordMonth].international[rec.tenantMonthRecordName] = { value: 0 }
                    }
                    if (rec.isDomestic) {
                        sums.years[rec.recordYear].months[rec.recordMonth].domestic[rec.tenantMonthRecordName].value += rec.recordValue
                    }
                    else {
                        sums.years[rec.recordYear].months[rec.recordMonth].international[rec.tenantMonthRecordName].value += rec.recordValue
                    }
                }
                console.log(sums)
                let recordArray = []
                while (startDate <= today) {
                    console.log(startDate)
                    console.log(today)
                    let record = {
                        year: startDate.getFullYear(),
                        month: startDate.getMonth(),
                        domPax: sums?.years[startDate.getFullYear()]?.months[startDate.getMonth()]?.domestic['Passengers']?.value || 0,
                        intlPax: sums?.years[startDate.getFullYear()]?.months[startDate.getMonth()]?.international['Passengers']?.value || 0,
                        domMail: sums?.years[startDate.getFullYear()]?.months[startDate.getMonth()]?.domestic['Mail']?.value || 0,
                        intlMail: sums?.years[startDate.getFullYear()]?.months[startDate.getMonth()]?.international['Mail']?.value || 0,
                        domFreight: sums?.years[startDate.getFullYear()]?.months[startDate.getMonth()]?.domestic['Freight']?.value || 0,
                        intlFreight: sums?.years[startDate.getFullYear()]?.months[startDate.getMonth()]?.international['Freight']?.value || 0
                    }
                    recordArray.push(record)
                    startDate.setMonth(startDate.getMonth() + 1)
                }
                console.log(recordArray)
                setDataRecords(recordArray)
            })
        })

        user.apiCall('parents').then(res => {
            console.log(res);
            setParents(res.data)
        })


    }, [])
    const managerBodyTemplate = (rowData) => {
        if (rowData.isManager) {
            return 'True'
        }
        return 'False'
    }

    const saveUser = () => {
        console.log(user)
        user.apiCall('users', { Email: newUserEmail, PhoneNumber: newUserPhone, IsManager: newUserManager, LastModifiedBy: user.username, LastLoggedIn: new Date() }, 'POST').then(res => {
            user.apiCall('tenants/' + tenantInfo.tenantId + '/users', { TenantId: tenantInfo.tenantId, UserId: res.data, UserEmail: newUserEmail, InvitedBy: user.username }, 'POST').then(res2 => {
                user.apiCall('tenants/' + tenantInfo.tenantId + '/users').then(res3 => {
                    setUsers(res3.data)
                    clearUser()
                })
            })

        })
    }
    const saveAircraft = () => {
        user.apiCall('tenants/' + tenantInfo.tenantId + '/aircrafts', { TenantAircraftName: newAircraftName, TenantId: tenantInfo.tenantId, FaaApprovedMaxGrossLw: newAircraftTonnage }, 'POST').then(res => {
                user.apiCall('tenants/' + tenantInfo.tenantId + '/aircrafts').then(res2 => {
                    setAircrafts(res2.data)
                    clearAircraft()
                })
            })

    }
    const clearUser = () => {
        setNewUserDialog(false)
        setNewUserManager(false)
        setNewUserEmail(null)
        setNewUserPhone(null)
    }
    const clearAircraft = () => {
        setNewAircraftDialog(false)
        setNewAircraftName(null)
        setNewAircraftTonnage(null)
    }
    const clearDialog = () => {
        setEditDialog(false)
    }
    const numToString = (rowData) => {
        return rowData.faaApprovedMaxGrossLw.toString();
    }
    const goToRecord = (event) => {
        history.push("/partners/" + tenantInfo.tenantName + "/" + event.data.year + '/' + event.data.month)
    }

    const deleteButtonBody = (rowData) => {
        console.log(rowData)
        if (!rowData.isManager) {
            return <Button icon="pi pi-times" style={{ marginLeft: '40%' }} className="p-button-rounded p-button-danger" aria-label="Cancel" onClick={() => {
                user.apiCall(`tenants/${rowData.tenantId}/users/${rowData.userId}`, {}, 'DELETE').then(() => {
                    user.apiCall('tenants/' + rowData.tenantId + '/users').then(res => {
                        setUsers(res.data)
                    })
                })
            }} />;
        }
    }
    const deleteButtonAircraft = (rowData) => {
        console.log(rowData)
            return <Button icon="pi pi-times" style={{ marginLeft: '40%' }} className="p-button-rounded p-button-danger" aria-label="Cancel" onClick={() => {console.log(rowData)
                user.apiCall(`tenants/${rowData.tenantId}/aircrafts/${rowData.tenantAircraftId}`, {}, 'DELETE').then(() => {
                   user.apiCall('tenants/' + rowData.tenantId + '/aircrafts').then(res => {
                       setAircrafts(res.data)
                   })
                })
            }} />;
        
    }
    const saveAction = () => {
        let body = {
            TenantId: tenantInfo.tenantId,
            TenantName: partnerName,
            TenantParentId: currentParent,
            DollarsPerThousandLbsRate: dollarRate
        }
        console.log(body)
        try {
            user.apiCall(`tenants`, { ...body }, 'POST').then(response => {
                console.log(response.data)
                history.push("/partners/" + partnerName)
                showSuccess()
            })
        }
        catch {
            showError()
        }
        clearDialog();
    }
    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Partner Saved', life: 3000 });
    }
    const showError = () => {
        toast.current.show({ severity: 'error', summary: 'Subspace relay falure', detail: 'Subspace relay falure.', life: 3000 });
    }
    const checkBodyTemplate = (rowData, props) => {
        if (rowData.registered === true) {
            const iconStyle = { color: "green",  fontSize: "30px" };
            const iconClassname = "pi pi-check valid" + rowData.userId;
            return (
                <span>
                    <Tooltip target={".valid" + rowData.userId}
                        content="Registered"
                        position="left" />
                    <i style={iconStyle} className={iconClassname}></i>
                </span>
            )
        }
        else {
            const iconStyle = { color: "red",  fontSize: "30px" };
            const iconClass = "pi pi-times error" + rowData.userId;
            return (
                <span>
                    <Tooltip target={".error" + rowData.userId}
                        content="Not Registered"
                        position="left"
                    />
                    <i style={iconStyle} className={iconClass}></i>
                </span >
            )
        }

    }
    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });
    }
    const dateBodyTemplate = (rowData) => {

        return formatDate(new Date(rowData.lastLoggedIn));
    }
    const monthBody = (rowData) => {

        return months[rowData.month];
    }
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <Toast ref={toast} />
                <div className="card">
                    <div style={{ display: "inline-block", width: "100%", marginBottom: "25px" }}>
                        <h5 style={{ display: "inline-block", fontSize: "26px", fontWeight: '700' }}>{partnerName}</h5>
                        <Button icon="pi pi-pencil" style={{color:'black', marginBottom:'10px'}} className="p-button-rounded p-button-text" aria-label="Edit" onClick={() => { setEditDialog(true) }} />
                    </div>
                    <div>
                        <Dialog header="Edit Partner" visible={editDialog} style={{ width: '10vw' }} onHide={clearDialog}>
                            <div style={{ display: "inline-block", width: "100%", marginBottom: "10px" }}>
                                <div style={{ width: "100%" }}>
                                    <div>
                                        <span style={{ width: '75%', marginTop:'30px' }} className="p-float-label">
                                            <Dropdown id="parent" value={currentParent} onChange={(e) => null} options={parents} optionLabel="tenantParentName" optionValue="tenantParentId" filter showClear filterBy="name" placeholder="Select a Parent" />
                                            <label htmlFor="parent">Parent</label>
                                        </span>
                                    </div>
                                    <span style={{ width: '75%', marginTop: '30px' }} className="p-float-label">
                                        <InputText id="partner" value={partnerName} onChange={(e) => setPartnerName(e.target.value)} />
                                        <label htmlFor="partner">Partner Name</label>
                                    </span>
                                    <span style={{ width: '75%', marginTop: '30px' }} className="p-float-label">
                                        <InputNumber id="rate" value={dollarRate} minFractionDigits={2} onValueChange={(e) => setDollarRate(e.value)} />
                                        <label htmlFor="rate">Dollar per 1000lbs rate</label>
                                    </span>
                                </div>
                                <Button style={{ marginTop: '30px'}} label="Submit" aria-label="Submit" onClick={saveAction} />
                            </div>
                        </Dialog>
                        <Dialog header="New User" visible={newUserDialog} style={{ width: '25vw' }} onHide={clearUser}>
                            <div style={{ display: 'block', width:'30%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ marginBottom: '10px', float: 'right' }} className="field col-12 md:col-4">
                                    <label style={{ marginRight: '10px' }} htmlFor="email">Email</label>
                                    <InputText  id="email" value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} />
                                </div> 
                                <div style={{ marginBottom: '10px', float: 'right' }} className="field col-12 md:col-4">
                                    <label style={{marginRight:'10px'}} htmlFor="phone">Phone</label>
                                    <InputMask  id="phone" mask="(999) 999-9999" value={newUserPhone} placeholder="(999) 999-9999" onChange={(e) => setNewUserPhone(parseInt(e.value))}></InputMask>
                                </div>
                                <div style={{ marginBottom: '10px' }} className="field col-12 md:col-4">
                                    <label style={{ marginRight: '20px' }} htmlFor="isManager">Manager</label>
                                    <Checkbox  id="isManager" onChange={e => setNewUserManager(e.checked)} checked={newUserManager}></Checkbox>                                
                                </div>
                            </div>
                            <Button style={{ width: "100%", marginTop: '10px' }} label='Add User' className="p-button-success" onClick={saveUser} />
                        </Dialog>
                        <Dialog header="New Aircraft" visible={newAircraftDialog} style={{ width: '25vw' }} onHide={clearAircraft}>
                            <div style={{ display: 'block', width: '30%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ marginBottom: '10px', float: 'right' }} className="field col-12 md:col-4">
                                    <label style={{ marginRight: '10px' }} htmlFor="aircraftName">Name</label>
                                    <InputText id="aircraftName" value={newAircraftName} onChange={(e) => setNewAircraftName(e.target.value)} />
                                </div>
                                <div style={{ marginBottom: '10px', float: 'right' }} className="field col-12 md:col-4">
                                    <label style={{ marginRight: '10px' }} htmlFor="tonnage">FAA Approved Max Gross Lift Weight LBS</label>
                                    <InputNumber id="tonnage" value={newAircraftTonnage} suffix=" lbs"  onChange={(e) => setNewAircraftTonnage(e.value)}/>
                                </div>
                            </div>
                            <Button style={{ width: "100%", marginTop: '10px' }} label='Add Aircraft' className="p-button-success" onClick={saveAircraft} />
                        </Dialog>
                        <Accordion style={{marginTop:'10px'}} activeIndex={[0,1,2]} multiple>
                            <AccordionTab header="Users">
                                <DataTable  value={users} sortOrder="1"  responsiveLayout="scroll" >
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center', fontSize:'14px' }} alignHeader='center' header="Email" field="email" ></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Phone" field="phoneNumber" ></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Manager Status" body={managerBodyTemplate} field="isManager" ></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Last Modified" field="invitedBy" ></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Registration Status" field="registered" body={checkBodyTemplate} ></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' body={dateBodyTemplate} header="Last Active" field="lastLoggedIn" ></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' body={deleteButtonBody} header={<Button style={{ marginLeft: '40%' }} label="Invite User" aria-label="Invite User"  onClick={() => setNewUserDialog(true)} />} ></Column>
                                </DataTable>
                            </AccordionTab>
                            <AccordionTab header="Aircraft Types">
                                <DataTable  value={aircrafts} sortOrder="1"  responsiveLayout="scroll" >
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Name" field="tenantAircraftName"  ></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' body={numToString} header="FAA Approved Max Gross Lift Weight LBS" field="faaApprovedMaxGrossLW"  ></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' body={deleteButtonAircraft} header={<Button style={{  marginLeft: '40%' }} label="New Plane Type" aria-label="New Plane Type"  onClick={() => setNewAircraftDialog(true)} />} ></Column>
                                </DataTable>
                            </AccordionTab>
                            <AccordionTab header="Data Records">
                                <DataTable value={dataRecords} multiSortMeta={[{ field: 'year', order: -1 }, { field: 'month', order: -1 }]} sortMode="multiple"  responsiveLayout="scroll" onRowClick={goToRecord}>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Year" field="year"  ></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} body={monthBody}  alignHeader='center' header="Month"   field="month"></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Domestic Pax" field='domPax' ></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Intl Pax" field='intlPax'></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Domestic Mail" field='domMail'></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Intl Mail" field='intlMail'></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Domestic Freight" field='domFreight'></Column>
                                    <Column bodyStyle={{ textAlign: 'center' }} headerStyle={{ textAlign: 'center' }} alignHeader='center' header="Intl Freight" field='intlFreight'></Column>
                                    <Column body={() => { return <Button icon="pi pi-pencil" style={{ color: 'black', marginBottom: '10px' }} className="p-button-rounded p-button-text" aria-label="Edit" disabled /> }}></Column>
                                </DataTable>
                            </AccordionTab>
                        </Accordion>                        
                    </div>
                </div>
            </div>
        </div>
    );
}
