import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
/*
	onMenuButtonClick
	onTopbarMenuButtonClick
*/
const AppTopbar = (props) => {
	const history = useHistory();
	const { instance, accounts } = useMsal();
	const toDashboard = () => {
		history.push('/');
	};
	function handleLogout(instance) {
		instance.logoutRedirect().catch(e => {
			console.error(e);
		});
	}
	return (
		<div className="topbar clearfix">
			<div id="home-div" className="topbar-left">
				<button className="p-link" onClick={toDashboard}>
					<img alt="Logo"
						src="assets/layout/images/logo.png"
						className="topbar-logo"
					/>
					<div>ACAA EPP</div>
				</button>
			</div>

            <div className="topbar-right">
				<button className="p-link"
					id="menu-button"
					onClick={props.onMenuButtonClick}>
					<i className="pi pi-angle-left"></i>
				</button>

				<button className="p-link"
					id="topbar-menu-button"
					onClick={props.onTopbarMenuButtonClick}>
					<i className="pi pi-bars"></i>
				</button>


				<button style={{ color: 'white', float: 'right' }} className="p-link" onClick={() => handleLogout(instance)}>
					<i className="pi pi-fw pi-sign-out"></i>
					<span>Sign Out</span>
				</button>


			</div>

		</div>
	);
}

export default AppTopbar;
