import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { UserContext, UserContextProvider } from '../UserContext.js'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useLocation } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { InputMask } from 'primereact/inputmask';
import { useHistory } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';

export const TenantAdv = () => {
    const [tenantInfo, setTenantInfo] = useState(null);
    const user = useContext(UserContext)
    const [advInfo, setAdvInfo] = useState([]);
    const [curAdv, setCurAdv] = useState({
        advDate: null,
        displayType: null,
        advertiser: null,
        siteNumber: null,
        revenue: null,
        notes: null,
        tenantId: '00000000-0000-0000-0000-000000000000',
        tenantAdvId: '00000000-0000-0000-0000-000000000000'
    });
    const location = useLocation();
    const toast = useRef(null);
    const mockData = {
        "security": [
            {
                "terminal": "PIT security",
                "advDate": "8/1/2023",
                "displayType": "Banner",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT8618BN.01",
                "revenue": "14254"
            },
            {
                "terminal": "PIT security",
                "advDate": "8/1/2023",
                "displayType": "Banner",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT8619BN.01",
                "revenue": "14254"
            },
            {
                "terminal": "PIT security",
                "advDate": "8/1/2023",
                "displayType": "Security Point",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT8999SE.01",
                "revenue": "5021"
            }
        ],
        "core": [
            {
                "terminal": "PIT Airside Core",
                "advDate": "8/1/2023",
                "displayType": "Banner",
                "advertiser": "UPMC",
                "siteNumber": "PIT1089BN.01",
                "revenue": "26388"
            }
        ],
        "transit": [
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Spectacular",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9051SO.01",
                "revenue": "1425"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Spectacular",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9054SO.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Spectacular",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9055SO.01",
                "revenue": "1425"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Spectacular",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9056SO.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9911WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9912WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9913WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9914WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9915WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9916WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9917WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9918WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9919WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9920WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9921WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9922WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9923WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9924WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9925WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9926WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9927WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9928WW.01",
                "revenue": "454"
            },
            {
                "terminal": "PIT Airside Transit",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT9929WW.01",
                "revenue": "454"
            }
        ],
        "baggage": [
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2142WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2143WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2145WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2146WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2147WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2148WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2149WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2150WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2151WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2152WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2153WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2159WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2160WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2161WW.01",
                "revenue": "286"
            },
            {
                "terminal": "PIT Baggage Claim",
                "advDate": "8/1/2023",
                "displayType": "Wall Wrap",
                "advertiser": "UPMC Health Plan",
                "siteNumber": "PIT2162WW.01",
                "revenue": "286"
            }
        ],
        "a": [
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT3090DA.C1",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT3090DA.C2",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT3091DA.C1",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT3091DA.C2",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT3090DA.C1",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT3090DA.C2",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT3091DA.C1",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT3091DA.C2",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT3090DA.C1",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT3090DA.C2",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT3091DA.C1",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT3091DA.C2",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse A",
                "advDate": "8/1/2023",
                "displayType": "Spectacular",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT3088SO.01",
                "revenue": "2123"
            }
        ],
        "b": [
            {
                "terminal": "PIT Concourse B",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT4111DA.C1",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse B",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT4111DA.C2",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse B",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT4112DA.C1",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse B",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT4112DA.C2",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse B",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT4111DA.C1",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse B",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT4111DA.C2",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse B",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT4112DA.C1",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse B",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT4112DA.C2",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse B",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT4111DA.C1",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse B",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT4111DA.C2",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse B",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT4112DA.C1",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse B",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT4112DA.C2",
                "revenue": "332"
            }
        ],
        "c": [
            {
                "terminal": "PIT Concourse C",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT5125DA.C1",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse C",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT5125DA.C2",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse C",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT5126DA.C1",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse C",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT5126DA.C2",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse C",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT5125DA.C1",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse C",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT5125DA.C2",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse C",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT5126DA.C1",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse C",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT5126DA.C2",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse C",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT5125DA.C1",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse C",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT5125DA.C2",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse C",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT5126DA.C1",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse C",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT5126DA.C2",
                "revenue": "332"
            }
        ],
        "d": [
            {
                "terminal": "PIT Concourse D",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT6501DA.C1",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse D",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT6501DA.C2",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse D",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT6502DA.C1",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse D",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Kraft Heinz Foods Company",
                "siteNumber": "PIT6502DA.C2",
                "revenue": "355"
            },
            {
                "terminal": "PIT Concourse D",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT6501DA.C1",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse D",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT6501DA.C2",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse D",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT6502DA.C1",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse D",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "Mercyhurst University",
                "siteNumber": "PIT6502DA.C2",
                "revenue": "167"
            },
            {
                "terminal": "PIT Concourse D",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT6501DA.C1",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse D",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT6501DA.C2",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse D",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT6502DA.C1",
                "revenue": "332"
            },
            {
                "terminal": "PIT Concourse D",
                "advDate": "8/1/2023",
                "displayType": "Prestige Digital Unit",
                "advertiser": "UPMC",
                "siteNumber": "PIT6502DA.C2",
                "revenue": "332"
            }
        ]
    };
    let partner;
    useEffect(() => {
        console.log(mockData)
        partner = location.pathname.split('/')[2]
        user.apiCall('tenants/' + partner + '/tenantInfo').then(response => {
            setTenantInfo(response.data[0]?.t)
            console.log(response.data[0]?.t)
            updateCurAdvInfo(response.data[0]?.t.tenantId, "tenantId")

        })
    }, [])
    const updateCurAdvInfo = ((value, field)  => {
        let tempInfo = { ...curAdv }
        tempInfo[field] = value;
        setCurAdv(tempInfo)
    })
    const saveAction = (() => {
        user.apiCall('tenants/' + tenantInfo?.tenantName + '/adv', curAdv, 'post').then(() => {
            partner = location.pathname.split('/')[2]
            user.apiCall('tenants/' + partner + '/adv').then((res) => {
                for (let x of res.data) {
                    let temp = x
                    temp.advDate = new Date(temp.advDate)
                } user.apiCall('tenants/' + partner + '/tenantInfo').then(response => {
                    setCurAdv({
                        advDate: null,
                        displayType: null,
                        advertiser: null,
                        siteNumber: null,
                        revenue: null,
                        notes: null,
                        tenantId: response.data[0].t.tenantId,
                        tenantAdvId: '00000000-0000-0000-0000-000000000000'
                    })
                })


                setAdvInfo(res.data)
            })
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Record Saved', life: 3000 });
        }).catch((e) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: e, life: 3000 });
        })
    })
    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });
    }
    const dateBodyTemplate = (rowData) => {

        return formatDate(new Date(rowData.advDate));
    }
    const revBodyTemplate = (rowData) => {

        return <InputNumber></InputNumber>
    }
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <h5 style={{ display: "inline-block", fontSize: "26px", fontWeight: '700' }}>{tenantInfo?.tenantName}</h5>
                <Toast ref={toast} />
                <Card title="Franchise Report">
                    <Button style={{ left: "0px", "marginBottom": "5px" }} label="Submit" />
                    <Accordion multiple activeIndex={[0,1,2,3,4,5,6,7]}>
                        <AccordionTab header="Security">
                            <DataTable value={mockData.security} >
                                <Column field="terminal" header="Terminal"></Column>
                                <Column body={dateBodyTemplate} field="advDate" header="Date"></Column>
                                <Column field="displayType" header="Display Type"></Column>
                                <Column field="advertiser" header="Advertiser"></Column>
                                <Column field="siteNumber" header="Site Number"></Column>
                                <Column body={revBodyTemplate} field="revenue" header="Revenue"></Column>
                            </DataTable>
                        </AccordionTab>
                        <AccordionTab header="Core">
                            <DataTable value={mockData.core} >
                                <Column field="terminal" header="Terminal"></Column>
                                <Column body={dateBodyTemplate} field="advDate" header="Date"></Column>
                                <Column field="displayType" header="Display Type"></Column>
                                <Column field="advertiser" header="Advertiser"></Column>
                                <Column field="siteNumber" header="Site Number"></Column>
                                <Column body={revBodyTemplate} field="revenue" header="Revenue"></Column>
                            </DataTable>
                        </AccordionTab>
                        <AccordionTab header="Transit">
                            <DataTable value={mockData.transit} >
                                <Column field="terminal" header="Terminal"></Column>
                                <Column body={dateBodyTemplate} field="advDate" header="Date"></Column>
                                <Column field="displayType" header="Display Type"></Column>
                                <Column field="advertiser" header="Advertiser"></Column>
                                <Column field="siteNumber" header="Site Number"></Column>
                                <Column body={revBodyTemplate} field="revenue" header="Revenue"></Column>
                            </DataTable>
                        </AccordionTab>
                        <AccordionTab header="Baggage">
                            <DataTable value={mockData.baggage} >
                                <Column field="terminal" header="Terminal"></Column>
                                <Column body={dateBodyTemplate} field="advDate" header="Date"></Column>
                                <Column field="displayType" header="Display Type"></Column>
                                <Column field="advertiser" header="Advertiser"></Column>
                                <Column field="siteNumber" header="Site Number"></Column>
                                <Column body={revBodyTemplate} field="revenue" header="Revenue"></Column>
                            </DataTable>
                        </AccordionTab>
                        <AccordionTab header="A">
                            <DataTable value={mockData.a} >
                                <Column field="terminal" header="Terminal"></Column>
                                <Column body={dateBodyTemplate} field="advDate" header="Date"></Column>
                                <Column field="displayType" header="Display Type"></Column>
                                <Column field="advertiser" header="Advertiser"></Column>
                                <Column field="siteNumber" header="Site Number"></Column>
                                <Column body={revBodyTemplate} field="revenue" header="Revenue"></Column>
                            </DataTable>
                        </AccordionTab>
                        <AccordionTab header="B">
                            <DataTable value={mockData.b} >
                                <Column field="terminal" header="Terminal"></Column>
                                <Column body={dateBodyTemplate} field="advDate" header="Date"></Column>
                                <Column field="displayType" header="Display Type"></Column>
                                <Column field="advertiser" header="Advertiser"></Column>
                                <Column field="siteNumber" header="Site Number"></Column>
                                <Column body={revBodyTemplate} field="revenue" header="Revenue"></Column>
                            </DataTable>
                        </AccordionTab>
                        <AccordionTab header="C">
                            <DataTable value={mockData.c} >
                                <Column field="terminal" header="Terminal"></Column>
                                <Column body={dateBodyTemplate} field="advDate" header="Date"></Column>
                                <Column field="displayType" header="Display Type"></Column>
                                <Column field="advertiser" header="Advertiser"></Column>
                                <Column field="siteNumber" header="Site Number"></Column>
                                <Column body={revBodyTemplate} field="revenue" header="Revenue"></Column>
                            </DataTable>
                        </AccordionTab>
                        <AccordionTab header="D">
                            <DataTable value={mockData.d} >
                                <Column field="terminal" header="Terminal"></Column>
                                <Column body={dateBodyTemplate} field="advDate" header="Date"></Column>
                                <Column field="displayType" header="Display Type"></Column>
                                <Column field="advertiser" header="Advertiser"></Column>
                                <Column field="siteNumber" header="Site Number"></Column>
                                <Column body={revBodyTemplate} field="revenue" header="Revenue"></Column>
                            </DataTable>
                        </AccordionTab>
                    </Accordion>
                    

                </Card>                
            </div>
        </div>
    );
}
